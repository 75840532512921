<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-text-box-search</v-icon>取引先情報</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="supplierForm" lazy-validation>
        <v-card-subtitle>会社情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-text-field
                label="仕入先名"
                v-model="updateModel.supplierName"
                :rules="[rules.required, rules.maxLength(100)]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="郵便番号"
                v-model="updateModel.postalCode"
                :rules="[rules.required, rules.zipCode]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="住所"
                v-model="updateModel.streetAddress"
                :rules="[rules.required, rules.maxLength(300)]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="電話番号"
                v-model="updateModel.telNumber"
                :rules="[rules.required, rules.phoneNo]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="FAX番号"
                v-model="updateModel.faxNumber"
                :rules="[rules.phoneNo]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="担当者"
                v-model="updateModel.staff"
                :rules="[rules.required, rules.maxLength(100)]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="roundingAttribute"
                :label="'端数処理属性'"
                dense
                :rules="[rules.required]"
                filled
                v-model="updateModel.roundingAttribute"
                :readonly="!allowedAction('M040107')"
                attach
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>請求情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select
                :items="vendorPaymentTermsClosingDate"
                label="締め日"
                dense
                filled
                v-model="updateModel.deadline"
                :readonly="true"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="vendorPaymentTermsPaymentDate"
                label="支払日"
                dense
                filled
                v-model="updateModel.paymentDate"
                :readonly="true"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="取引銀行" v-model="updateModel.bank" filled dense :readonly="true"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="支店" v-model="updateModel.branch" filled dense :readonly="true"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                :items="accountTypes"
                label="種別"
                dense
                filled
                v-model="updateModel.accountType"
                :readonly="true"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="口座番号" v-model="updateModel.accountNumber" filled dense readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                label="登録番号"
                v-model="updateModel.accountNumber"
                filled
                dense
                readonly
              ></v-text-field> </v-col
          ></v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>付加情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-textarea
                label="備考"
                v-model="updateModel.remarks"
                :rules="[rules.maxLength(300)]"
                filled
                dense
                :readonly="!allowedAction('M040107')"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick" v-if="allowedAction('M040107')">更新</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VendorPaymentTermsClosingDate from "../../consts/PaymentTermsClosingDate";
import VendorPaymentTermsPaymentDate from "../../consts/PaymentTermsPaymentDate";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import AccountTypes from "../../consts/AccountTypes";
import RoundingAttribute from "../../consts/RoundingAttribute";

export default {
  name: "CorporationEdit",
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
      },
      updateModel: {
        supplierCode: null,
        status: null,
        supplierName: null,
        postalCode: null,
        streetAddress: null,
        telNumber: null,
        faxNumber: null,
        staff: null,
        roundingAttribute: null,
        deadline: null,
        paymentDate: null,
        bank: null,
        branch: null,
        accountType: null,
        accountNumber: null,
        invoiceRegistrationNumber: null,
        remarks: null,
      },
      validEntryForm: null,
      vendorPaymentTermsClosingDate: VendorPaymentTermsClosingDate.all(),
      vendorPaymentTermsPaymentDate: VendorPaymentTermsPaymentDate.all(),
      roundingAttribute: RoundingAttribute.all(),
      accountTypes: AccountTypes.all(),
    };
  },
  components: {},
  beforeMount() {
    if (this.$refs.supplierForm) {
      this.$refs.supplierForm.resetValidation();
    }
    this.onSearchClick();
  },
  methods: {
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("supplier/search");

        if (ApiStatus.isSystemError(response?.data?.header)) {
          return this.redirectError();
        }
        this.updateModel = {
          supplierCode: response.data.contents.suppliers[0].supplierCode,
          status: response.data.contents.suppliers[0].status,
          supplierName: response.data.contents.suppliers[0].supplierName,
          postalCode: response.data.contents.suppliers[0].postalCode,
          streetAddress: response.data.contents.suppliers[0].streetAddress,
          telNumber: response.data.contents.suppliers[0].telNumber,
          faxNumber: response.data.contents.suppliers[0].faxNumber,
          staff: response.data.contents.suppliers[0].staff,
          roundingAttribute: response.data.contents.suppliers[0].roundingAttribute,
          deadline: response.data.contents.suppliers[0].deadline,
          paymentDate: response.data.contents.suppliers[0].paymentDate,
          bank: response.data.contents.suppliers[0].bank,
          branch: response.data.contents.suppliers[0].branch,
          accountType: response.data.contents.suppliers[0].accountType,
          accountNumber: response.data.contents.suppliers[0].accountNumber,
          remarks: response.data.contents.suppliers[0].remarks,
          updateDateTime: response.data.contents.suppliers[0].updateDatetime,
        };
      } catch (error) {
        console.error("SupplierEdit::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("supplier/update", { supplier: this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`取引先情報を更新しました (${this.updateModel.supplierCode})`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              this.$dialog.warning({
                title: `更新`,
                text: result.data.header.messages[this.updateModel.supplierCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("SupplierEdit::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.supplierForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
