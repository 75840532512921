<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(false)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。</p>
              <p v-if="errorRows.length > 0">配送先情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(true)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="配送先一括取込エラー一覧"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import DeliveryStatus from "../../consts/DeliveryStatus";
import SlipIssuanceType from "../../consts/SlipIssuanceType";

export default {
  name: "DeliveryDestinationImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridApi: null,
    columnApi: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [
        { headerName: "取引先CD", field: "groupCode" },
        { headerName: "取引先名", field: "groupName" },
        { headerName: "配送先〒", field: "deliveryZipCode" },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: "届先名称", field: "delivery2" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "配送先TEL", field: "deliveryPhoneNumber" },

        {
          headerName: "ステータス",
          field: "deliveryStatus",
          filterParams: { options: DeliveryStatus.all() },
          valueGetter: (params) => DeliveryStatus.of(params.data.deliveryStatus),
        },
        {
          headerName: "ルート配送",
          field: "routeDelivery",
        },
        {
          headerName: "伝票発行区分",
          field: "slipIssuance_type",
          filterParams: { options: SlipIssuanceType.all() },
          valueGetter: (params) => SlipIssuanceType.of(params.data.slipIssuance_type),
        },
        {
          headerName: "倉庫間移動出荷",
          field: "transferWarehouses",
        },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxByteLength: Validation.maxByteLength,
      maxByteLengthSjis: Validation.maxByteLengthSjis,
      maxLength: Validation.maxLength,
      phoneNo: Validation.phoneNo,
      mailAddress: Validation.mailAddress,
      zipCode: Validation.isZipcode,
      isCsv: Validation.isCsv,
      isCustomer: Validation.isCustomer,
      isDeliveryZipArea: Validation.isDeliveryZipArea,
      isBoolean: Validation.isBoolean4Import,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          const csvLines = await FileUtils.readAsCsvSpecQuote(this.file, true);
          this.csvRecords = [];
          let index = 0;
          for (const line of csvLines) {
            if (index !== 0 && line.length > 1) {
              const response = await this.$store.dispatch("customer/search", {
                customerCode: line[0],
              });
              let customers = response.data?.contents.customers;
              this.csvRecords.push({
                rowNumber: index,
                groupCode: line[0],
                groupName: customers ? customers[0].customerName : "",
                deliveryZipCode: line[1],
                delivery1: line[2],
                delivery2: line[3],
                deliveryAddress: line[4],
                deliveryPhoneNumber: line[5],
                deliveryStatus: line[6],
                routeDelivery: line[7],
                slipIssuance_type: line[8],
                transferWarehouses: line[9],
              });
            }
            index++;
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async validLoop(records) {
      let errors = true;
      for await (const record of records) {
        const error = await this.validateRow(record);
        if (error != true) {
          errors = false;
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
        }
      }
      return errors;
    },
    async onSubmit() {
      this.loadingOn();
      try {
        this.errorRows = [];
        const error = await this.validLoop(this.csvRecords);
        if (error) {
          let requestRecords = [];
          this.csvRecords.forEach(async (record) => {
            requestRecords.push(this.requestFormat(record));
          });
          this.errorRows = [];
          var param = {
            deliveries: requestRecords,
          };
          const response = await this.$store.dispatch("deliveryDestination/import", param);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importCount = response.data.contents.deliveries.length;
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.$refs.importErrorGrid.open({ records: this.errorRows });
          this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("LogisticsStockImport::onSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    async validateRow(row) {
      var ret = true;
      var messages = [];
      console.log("row:::::");
      console.log(row);
      // 取引先CD
      this.setValidMessage(this.rules.required(row.groupCode), "取引先CD", messages);
      this.setValidMessage(await this.rules.isCustomer(row.groupCode), "取引先CD", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.groupCode), "法人CD", messages);
      // 郵便番号
      this.setValidMessage(this.rules.zipCode(row.deliveryZipCode), "郵便番号", messages);
      this.setValidMessage(await this.rules.isDeliveryZipArea(row.deliveryZipCode), "郵便番号", messages);
      // 届先法人名
      this.setValidMessage(this.rules.required(row.delivery1), "届先法人名", messages);
      this.setValidMessage(this.rules.maxByteLengthSjis(50)(row.delivery1), "届先法人名", messages);
      // 届先名称
      this.setValidMessage(this.rules.required(row.delivery2), "届先名称", messages);
      this.setValidMessage(this.rules.maxByteLengthSjis(30)(row.delivery2), "届先名称", messages);
      // 所在地（住所）
      this.setValidMessage(this.rules.maxLength(150)(row.deliveryAddress), "所在地（住所）", messages);
      // 電話番号
      this.setValidMessage(this.rules.phoneNo(row.deliveryPhoneNumber), "電話番号", messages);
      // ステータス
      this.setValidMessage(this.rules.required(row.deliveryStatus), "ステータス", messages);
      this.setValidMessage(this.isStatus(row.deliveryStatus), "ステータス", messages);
      // ルート配送
      this.setValidMessage(this.rules.required(row.routeDelivery), "ルート配送", messages);
      this.setValidMessage(this.rules.isBoolean(row.routeDelivery), "ルート配送", messages);
      // 伝票発行区分
      this.setValidMessage(this.rules.required(row.slipIssuance_type), "伝票発行区分", messages);
      this.setValidMessage(this.isStatus(row.slipIssuance_type), "伝票発行区分", messages);
      // 倉庫間移動出荷
      this.setValidMessage(this.rules.required(row.transferWarehouses), "倉庫間移動出荷", messages);
      this.setValidMessage(this.rules.isBoolean(row.transferWarehouses), "倉庫間移動出荷", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        groupCode: this.stringFormat(row.groupCode),
        deliveryZipCode: this.stringFormat(row.deliveryZipCode),
        delivery1: this.stringFormat(row.delivery1),
        delivery2: this.stringFormat(row.delivery2),
        deliveryAddress: this.stringFormat(row.deliveryAddress),
        deliveryPhoneNumber: this.stringFormat(row.deliveryPhoneNumber),
        deliveryStatus: this.numberFormat(row.deliveryStatus),
        routeDelivery: this.boolFormat(row.routeDelivery),
        slipIssuance_type: this.numberFormat(row.slipIssuance_type),
        transferWarehouses: this.boolFormat(row.transferWarehouses),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(new Date(value)).format("YYYY-MM-DD");
    },
    boolFormat(value) {
      if (value == null || value == "") return false;
      return value == 1;
    },

    isStatus(value) {
      if (value == null || value == "") return true;
      if (!DeliveryStatus.of(value)) return "1～3の形式で入力してください";
      return true;
    },

    isSlipIssuanceType(value) {
      if (value == null || value == "") return true;
      if (!SlipIssuanceType.of(value)) return "1～3の形式で入力してください";
      return true;
    },

    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
