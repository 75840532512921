<template>
  <v-card>
    <v-card-title v-if="!vendorFlg">
      <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>法人登録</span>
      <span class="headline" v-if="!inputModel.createNew"><v-icon>mdi-pencil-outline</v-icon>法人編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="corporationForm" v-model="validEntryForm" lazy-validation>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="法人名"
                v-model="updateModel.corporationName"
                :rules="[rules.required, rules.maxLength(150)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field
                label="電話番号"
                v-model="updateModel.phoneNumber"
                filled
                dense
                :rules="[rules.required, rules.phoneNo]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field label="FAX番号" v-model="updateModel.faxNumber" filled dense :rules="[rules.phoneNo]">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field
                label="郵便番号"
                v-model="updateModel.zipCode"
                filled
                dense
                :rules="[rules.required, rules.zipCode]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                label="都道府県"
                v-model="updateModel.prefecture"
                filled
                dense
                :rules="[rules.required, rules.maxLength(4)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="所在地（住所）"
                v-model="updateModel.locationAddress"
                filled
                dense
                :rules="[rules.required, rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="所在地（ビル名）"
                v-model="updateModel.locationBuilding"
                filled
                dense
                :rules="[rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6" v-if="inputModel.corporationType != 1">
              <v-select
                :items="corporationType"
                :label="'法人区分'"
                dense
                filled
                v-model="updateModel.corporationType"
                :rules="[rules.cafereo]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-select
                :items="corporateStatus"
                :label="'ステータス'"
                dense
                filled
                v-model="updateModel.corporateStatus"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <dp-date-picker
                type="date"
                label="取引開始日"
                v-model="updateModel.startTradingDate"
                inset
                dense
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="6" sm="6">
              <v-switch label="与信" v-model="updateModel.credit" inset dense :rules="[rules.required]"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-select
                :items="invoiceType"
                label="請求先区分"
                dense
                filled
                v-model="updateModel.invoiceType"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-select
                :items="roundingTypes"
                label="端数処理区分"
                dense
                filled
                v-model="updateModel.roundingType"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <customer-field
                v-if="!inputModel.createNew && updateModel.corporationType === 3"
                :corporate="updateModel.corporateCode"
                :code="updateModel.headOffice"
                label="本部店舗"
                v-model="updateModel.headOffice"
                :rules="[rules.invoiceAll]"
                :disabled="updateModel.invoiceType !== 1"
              ></customer-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field label="担当部署" v-model="updateModel.salesDepartment" filled dense disabled>
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <cafereo-user-field
                ref="salesStaff"
                label="営業担当"
                :value="updateModel.salesStaff"
                v-model="updateModel.salesStaff"
              ></cafereo-user-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <cafereo-users-field
                ref="salesAssistant"
                label="担当AS"
                :value="updateModel.salesAssistant"
                v-model="updateModel.salesAssistant"
              ></cafereo-users-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="備考"
                v-model="updateModel.description"
                filled
                dense
                :rules="[rules.maxLength(100)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="!vendorFlg">
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import CorporationTypes from "../../consts/CorporationTypes";
import CorporationStatus from "../../consts/CorporationStatus";
import WholesaleRatePatterns from "../../consts/WholesaleRatePatterns";
import Validation from "../../libs/validation";
import InvoiceTypes from "../../consts/InvoiceTypes";
import RoundingTypes from "../../consts/RoundingTypes";
import CustomerField from "../../components/common/fields/CustomerField.vue";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import CafereoUsersField from "../../components/common/fields/CafereoUsersField.vue";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "CorporationEntry",
  props: ["inputModel", "vendorFlg"],
  components: {
    CustomerField,
    CafereoUserField,
    CafereoUsersField,
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        decimal: (value) => value?.toString().match(/^(\d{0,3})(\.\d{0,1})?$/) != null || "不正な形式です",
        invoiceAll: (value) => this.updateModel.invoiceType != 1 || Validation.required(value),
        cafereo: (value) => this.inputModel.corporationType == CorporationTypes.CAFEREO || Validation.required(value),
      },
      validEntryForm: null,
      corporationType: CorporationTypes.all(),
      corporateStatus: CorporationStatus.all(),
      wholesaleRatePatterns: WholesaleRatePatterns.all(),
      invoiceType: InvoiceTypes.all(),
      roundingTypes: RoundingTypes.all(),
      updateModel: null,
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
    "updateModel.salesStaff"() {
      if (this.$refs?.salesStaff?.current != null) {
        this.updateModel.salesDepartment = this.$refs.salesStaff.current?.department;
      }
    },
    "updateModel.invoiceType"() {
      if (this.updateModel.invoiceType != 1) {
        this.updateModel.headOffice = null;
      }
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = {
        createNew: inputModel.createNew,
        corporateCode: inputModel.corporateCode
          ? inputModel.corporateCode
          : this.$store.getters["security/loggedInUser"].corporateCode,
        corporationName: inputModel.corporationName,
        phoneNumber: inputModel.phoneNumber,
        faxNumber: inputModel.faxNumber,
        zipCode: inputModel.zipCode,
        prefecture: inputModel.prefecture,
        locationAddress: inputModel.locationAddress,
        locationBuilding: inputModel.locationBuilding,
        corporateStatus: inputModel.corporateStatus,
        startTradingDate: inputModel.startTradingDate,
        credit: inputModel.credit,
        invoiceType: inputModel.invoiceType,
        roundingType: inputModel.roundingType,
        headOffice: inputModel.headOffice,
        salesDepartment: inputModel.salesDepartment,
        salesStaff: inputModel.salesStaff,
        salesAssistant: inputModel.salesAssistant,
        description: inputModel.description,
        corporationType: inputModel.corporationType,
        updateDatetime: inputModel.updateDatetime,
      };
      if (this.$refs.corporationForm) {
        this.$refs.corporationForm.resetValidation();
      }
      if (this.updateModel.createNew) {
        this.invoiceType = this.invoiceType.filter((item) => item.value !== 1);
      } else {
        this.invoiceType = InvoiceTypes.all();
      }
    },
    reset() {
      this.initUpdateModel(this.updateModel);
    },

    getUpdateModel() {
      return this.updateModel;
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("corporation/update", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents.corporation);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `法人編集`,
                text: result.data.header.messages[this.updateModel.corporateCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("corporation/create", { ...this.updateModel });
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.loadingOff();
          this.$emit("onEntrySubmit", result.data.contents.corporation);
        } catch (error) {
          console.error("CorporationUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },

    validate() {
      const isValid = this.$refs.corporationForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
