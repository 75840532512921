<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>仕入先登録</span>
      <span class="headline" v-if="!inputModel.createNew"><v-icon>mdi-pencil-outline</v-icon>仕入先編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="supplierForm" lazy-validation>
        <v-card-subtitle>仕入先情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="8">
              <corporation-field
                ref="corporations"
                :group="supplierCorporationType"
                :value="updateModel.corporationCd"
                v-model="updateModel.corporationCd"
                :rules="[rules.required]"
              ></corporation-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-text-field
                label="仕入先名"
                v-model="updateModel.supplierName"
                :rules="[rules.required, rules.maxLength(100)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="郵便番号"
                v-model="updateModel.postalCode"
                :rules="[rules.required, rules.zipCode]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="住所"
                v-model="updateModel.streetAddress"
                :rules="[rules.required, rules.maxLength(300)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="電話番号"
                v-model="updateModel.telNumber"
                :rules="[rules.required, rules.phoneNo]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="FAX番号"
                v-model="updateModel.faxNumber"
                :rules="[rules.phoneNo]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="仕入先担当者"
                v-model="updateModel.supplierStaff"
                :rules="[rules.required, rules.maxLength(100)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                :items="supplierStatus"
                :label="'ステータス'"
                dense
                filled
                v-model="updateModel.status"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="仕入先略称"
                v-model="updateModel.maker"
                :rules="[rules.required, rules.maxLength(150)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-text-field
                label="担当部署"
                v-model="updateModel.salesDepartment"
                :rules="[rules.maxLength(150), rules.required]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="roundingAttribute"
                :label="'端数処理属性'"
                dense
                :rules="[rules.required]"
                filled
                v-model="updateModel.roundingAttribute"
                attach
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>請求情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select
                :items="paymentTermsClosingDate"
                label="締め日"
                dense
                :rules="[rules.required]"
                filled
                v-model="updateModel.deadline"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="paymentTermsPaymentDate"
                label="支払日"
                :rules="[rules.required]"
                dense
                filled
                v-model="updateModel.paymentDate"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="取引銀行"
                v-model="updateModel.bank"
                :rules="[rules.required, rules.maxLength(100)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="支店"
                v-model="updateModel.branch"
                :rules="[rules.required, rules.maxLength(300)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                :items="accountTypes"
                label="種別"
                dense
                filled
                :rules="[rules.required]"
                v-model="updateModel.accountType"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="口座番号"
                v-model="updateModel.accountNumber"
                :rules="[rules.required, rules.maxLength(7), rules.isNumber]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="登録番号"
                v-model="updateModel.invoiceRegistrationNumber"
                :rules="[rules.maxLength(24)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>付加情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4">
              <cafereo-user-field
                ref="stockStaff"
                label="仕入担当"
                :value="updateModel.stockStaff"
                v-model="updateModel.stockStaff"
                :rules="[rules.required]"
              ></cafereo-user-field>
            </v-col>
            <v-col cols="12" sm="4">
              <cafereo-users-field
                ref="salesAssistant"
                label="アシスタント"
                :value="updateModel.assistant"
                v-model="updateModel.assistant"
                :rules="[rules.required]"
              ></cafereo-users-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-textarea label="備考" v-model="updateModel.remarks" :rules="[rules.maxLength(100)]" filled dense>
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import CorporationTypes from "../../consts/CorporationTypes";
import AccountTypes from "../../consts/AccountTypes";
import RoundingAttribute from "../../consts/RoundingAttribute";
import SupplierStatus from "../../consts/SupplierStatus";
import Validation from "../../libs/validation";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import { VendorPaymentTermsClosingDate, MakerPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate, MakerPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import CafereoUsersField from "../../components/common/fields/CafereoUsersField.vue";

export default {
  name: "SupplierEntry",
  props: ["corporations", "inputModel"],
  components: {
    CorporationField,
    CafereoUserField,
    CafereoUsersField,
  },
  data() {
    return {
      supplierCorporationType: CorporationTypes.MAKER,
      supplierStatus: SupplierStatus.all(),
      accountTypes: AccountTypes.all(),
      roundingAttribute: RoundingAttribute.all(),
      vpaymentTermsClosingDate: VendorPaymentTermsClosingDate.all(),
      vpaymentTermsPaymentDate: VendorPaymentTermsPaymentDate.all(),
      paymentTermsClosingDate: MakerPaymentTermsClosingDate.all(),
      paymentTermsPaymentDate: MakerPaymentTermsPaymentDate.all(),
      updateModel: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        isNumber: Validation.isNumber,
      },
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = {
        createNew: inputModel.createNew,
        supplierCode: inputModel.supplierCode,
        corporationCd: inputModel.corporationCd,
        supplierName: inputModel.supplierName,
        postalCode: inputModel.postalCode,
        streetAddress: inputModel.streetAddress,
        telNumber: inputModel.telNumber,
        faxNumber: inputModel.faxNumber,
        supplierStaff: inputModel.supplierStaff,
        status: inputModel.status,
        stockStaff: inputModel.stockStaff,
        maker: inputModel.maker,
        salesDepartment: inputModel.salesDepartment,
        roundingAttribute: inputModel.roundingAttribute,
        deadline: inputModel.deadline,
        paymentDate: inputModel.paymentDate,
        bank: inputModel.bank,
        branch: inputModel.branch,
        accountType: inputModel.accountType,
        accountNumber: inputModel.accountNumber,
        assistant: inputModel.assistant,
        invoiceRegistrationNumber: inputModel.invoiceRegistrationNumber,
        remarks: inputModel.remarks,
        updateDatetime: inputModel.updateDatetime,
      };
      if (this.$refs.supplierForm) {
        this.$refs.supplierForm.resetValidation();
      }
    },
    reset() {
      this.initUpdateModel(this.inputModel);
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("supplier/create", { ...this.updateModel });
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.loadingOff();
          this.$emit("onEntrySubmit", result.data.contents);
        } catch (error) {
          console.error("SupplierUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("supplier/update", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: "仕入先編集",
                text: result.data.header.messages[this.updateModel.supplierCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("SupplierUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.supplierForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
