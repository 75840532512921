<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>{{ `${screenName}一覧` }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" v-if="allowedAction('C070301')">
            <v-text-field
              v-model="searchModel.customerCode"
              label="社店CD"
              :rules="[rules.isCode, rules.maxLength(12)]"
              dense
            ></v-text-field>
            <v-text-field v-model="searchModel.corporateName" label="法人名" dense></v-text-field>
          </search-conditions>
          <search-conditions @search="onBtnSearch" v-if="allowedAction('V030301')">
            <v-text-field
              v-model="searchModel.customerCode"
              label="社店CD"
              :rules="[rules.isCode, rules.maxLength(12)]"
              dense
            ></v-text-field>
            <v-text-field v-model="searchModel.customerName" label="取引先名" dense></v-text-field>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button v-if="allowedAction('C070302')" icon="mdi-pencil-plus-outline" @click="onBtnInsert"
          >新規登録</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction('C070303')" icon="mdi-database-import" @click="onBtnImport"
          >一括取込</tooltip-icon-button
        >
        <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
          <customer-import
            @complete="onImportComplete"
            @expand="importDialog.width = $event ? '100%' : '800px'"
            v-if="importDialog.show"
          ></customer-import>
        </v-dialog>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction('C070304', 'V030302')"
          :disabled="!selectedRow || vendorDisabledRule"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <customer-entry
            ref="customerEntry"
            :inputModel="editModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></customer-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction('C070305')"
          :disabled="selectionRows.length === 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="allowedAction('C070306')"
          icon="mdi-file-download"
          :disabled="!selectedRow"
          @click="onBtnPdfExport"
          >宛名印刷</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="allowedAction('C070308')"
          :disabled="selectionRows.length === 0"
          icon="mdi-database-edit"
          @click="onBtnUpdatePic"
          >担当者更新</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction('C070307', 'V030304')" icon="mdi-download" @click="onBtnExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="CustomerList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.customerCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="CustomerInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <customer-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :customerCode="selectedRow != null ? selectedRow.customerCode : null"
        ></customer-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import CustomerInfos from "../../components/customer/CustomerInfos.vue";
import CustomerEntry from "../../components/customer/CustomerEntry.vue";
import CustomerImport from "../../components/customer/CustomerImport.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { NumericColumn, FullDateColumn, DateColumn, CheckmarkColumn } from "../../models/ag-grid/columnTypes";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { statuses as ApiStatus } from "../../libs/api-client";
import MasterStatuses from "../../consts/CustomerStatus";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import ShipmentDayOfWeek from "../../consts/ShipmentDayOfWeek";
import { VendorPaymentTermsClosingDate, MakerPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate, MakerPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import Validation from "../../libs/validation";

export default {
  name: "CustomerSearch",
  components: {
    AgGridVue,
    CustomerInfos,
    CustomerEntry,
    CustomerImport,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isCode: Validation.isCode,
      },
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
      ],
      defaultCsvExportParams: {
        allColumns: true,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {
        customerCode: "",
        corporationName: "",
        customerName: "",
        pauseDisplayFlg: true,
      },
      editDialog: { show: false, width: "800px" },
      editModel: {
        createNew: false,
        rowId: null,
        companyStoreCode: null,
        corporationCode: null,
        companyName: null,
        storeName: null,
        phoneNumber: null,
        faxNumber: null,
        zipCode: null,
        location: null,
        customerStaff: null,
        paymentTermsClosingDate: null,
        paymentTermsPaymentDate: null,
        paymentMethod: null,
        invoiceZipCode: null,
        invoiceAddress: null,
        invoicePhoneNumber: null,
        invoiceStaff: null,
        invoiceMailingAddress: null,
        remarks: null,
        invoiceApproval: null,
        shipmentLowerPriceUpperLimit: null,
        shipmentDayOfWeek: null,
        useOricon: null,
        enhancedInspection: null,
        divamentApproval: null,
        forecast: null,
        productListLayout: null,
        orderPriceLimit: null,
        ourSalesStaff: null,
        assistant: null,
      },
      importDialog: { show: false, width: "800px" },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      mpaymentTermsClosingDate: MakerPaymentTermsClosingDate.all(),
      mpaymentTermsPaymentDate: MakerPaymentTermsPaymentDate.all(),
      cafereoColumnDefs: [
        { headerName: "社店CD", field: "customerCode", pinned: "left" },
        {
          headerName: "ステータス",
          field: "customerStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: MasterStatuses.all() },
          valueGetter: (params) => MasterStatuses.of(params.data.customerStatus),
        },
        { headerName: "担当部署", field: "salesDepartment" },
        { headerName: "営業担当", field: "ourSalesStaff" },
        { headerName: "担当AS", field: "assistant" },
        { headerName: "法人CD", field: "corporateCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "郵便番号", field: "zipCode" },
        { headerName: "都道府県", field: "prefecture" },
        { headerName: "所在地（住所）", field: "locationAddress" },
        { headerName: "所在地（ビル名）", field: "locationBuilding" },
        { headerName: "電話番号", field: "telNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "担当者", field: "customerStaff" },
        {
          headerName: "社内発注上限金額",
          field: "inCompanyOrderMaxAmount",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "支払条件締日",
          field: "paymentTermsClosingDate",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorPaymentTermsClosingDate.all() },
          valueGetter: (params) => VendorPaymentTermsClosingDate.of(params.data.paymentTermsClosingDate),
        },
        {
          headerName: "支払条件支払日",
          field: "paymentTermsPaymentDate",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorPaymentTermsPaymentDate.all() },
          valueGetter: (params) => VendorPaymentTermsPaymentDate.of(params.data.paymentTermsPaymentDate),
        },

        {
          headerName: "出荷曜日指定",
          field: "shipmentDayOfWeek",
          filter: "dpSelectionFilter",
          filterParams: { options: ShipmentDayOfWeek.all() },
          valueGetter: (params) => {
            let shipmentDayOfWeekName = "";
            params.data.shipmentDayOfWeek.forEach(function (elem) {
              if (shipmentDayOfWeekName != "") {
                shipmentDayOfWeekName += ",";
                shipmentDayOfWeekName += ShipmentDayOfWeek.of(elem);
              } else {
                shipmentDayOfWeekName += ShipmentDayOfWeek.of(elem);
              }
            });
            return shipmentDayOfWeekName;
          },
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "フォーキャスト可否",
          field: "forecast",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "可能", false: "不可" } },
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "請求書発行可否",
          field: "invoiceApproval",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "可能", false: "不可" } },
          type: "dpCheckmarkColumn",
        },
        { headerName: "請求先〒", field: "invoiceZipCode" },
        { headerName: "請求先住所", field: "invoiceAddress" },
        { headerName: "請求先TEL", field: "invoicePhoneNumber" },
        { headerName: "請求担当者", field: "invoiceStaff" },
        { headerName: "請求書宛名", field: "invoiceMailingAddress" },
        { headerName: "取引開始年月日", field: "transactionStartDate", type: "dpDateColumn" },
        { headerName: "備考", field: "remarks" },
        { headerName: "倉庫備考", field: "warehouseRemarks" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      vendorColumnDefs: [
        {
          headerName: "ステータス",
          field: "customerStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: MasterStatuses.all() },
          valueGetter: (params) => MasterStatuses.of(params.data.customerStatus),
        },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "郵便番号", field: "zipCode" },
        { headerName: "都道府県", field: "prefecture" },
        { headerName: "所在地（住所）", field: "locationAddress" },
        { headerName: "所在地（ビル名）", field: "locationBuilding" },
        { headerName: "電話番号", field: "telNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "担当者", field: "customerStaff" },
        { headerName: "当社担当者", field: "ourSalesStaff" },
        { headerName: "当社アシスタント", field: "assistant" },
        { headerName: "社内発注上限金額", field: "inCompanyOrderMaxAmount" },
      ],
    };
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    if (this.isCafereoUser) this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
    if (this.isVendorUser) this.columnDefs = this.columnDefs.concat(this.vendorColumnDefs);
  },
  async mounted() {
    try {
      this.loadingOn();
      this.onSearchClick();
      this.handleResize();
      // 画面解像度による画面サイズ取得
      this.gridStyle.height = this.gridHeightSize + "px";
      window.addEventListener("resize", this.handleResize);
    } catch (error) {
      console.error("CustomerSearch::mounted", error);
      this.loadingOff();
      this.apiRequestError(error);
    }
  },
  computed: {
    screenName() {
      return this.isCafereoUser ? "取引先" : "店舗";
    },
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.customerCode, this.gridApi, this.columnApi);
    },
    vendorDisabledRule() {
      return (
        this.isVendorUser &&
        this.selectionRows.some((r) => r.customerCode !== this.$store.getters["security/loggedInUser"].groupCode)
      );
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `${this.screenName}一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnInsert() {
      this.editModel = {
        createNew: true,
        corporateCode: this.$store.getters["security/loggedInUser"].corporateCode,
        customerCode: null,
        customerName: null,
        zipCode: null,
        prefecture: null,
        locationAddress: null,
        locationBuilding: null,
        telNumber: null,
        faxNumber: null,
        customerStaff: null,
        inCompanyOrderMaxAmount: null,
        customerStatus: null,
        paymentTermsClosingDate: null,
        paymentTermsPaymentDate: null,
        transactionStartDate: null,
        shipmentDayOfWeek: null,
        useOricon: false,
        forecast: false,
        autoShipRequest: false,
        remarks: null,
        warehouseRemarks: null,
        invoiceApproval: false,
        invoiceZipCode: null,
        invoiceAddress: null,
        invoicePhoneNumber: null,
        invoiceStaff: null,
        invoiceMailingAddress: null,
        salesDepartment: null,
        ourSalesStaff: null,
        assistant: null,
        janDisplay: false,
        updateDatetime: null,
      };
      this.editDialog.show = true;
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
        corporateCode: this.selectedRow.corporateCode,
        customerCode: this.selectedRow.customerCode,
        customerName: this.selectedRow.customerName,
        zipCode: this.selectedRow.zipCode,
        prefecture: this.selectedRow.prefecture,
        locationAddress: this.selectedRow.locationAddress,
        locationBuilding: this.selectedRow.locationBuilding,
        telNumber: this.selectedRow.telNumber,
        faxNumber: this.selectedRow.faxNumber,
        customerStaff: this.selectedRow.customerStaff,
        inCompanyOrderMaxAmount: this.selectedRow.inCompanyOrderMaxAmount,
        customerStatus: this.selectedRow.customerStatus,
        paymentTermsClosingDate: this.selectedRow.paymentTermsClosingDate,
        paymentTermsPaymentDate: this.selectedRow.paymentTermsPaymentDate,
        transactionStartDate: this.selectedRow.transactionStartDate,
        shipmentDayOfWeek: this.selectedRow.shipmentDayOfWeek,
        useOricon: this.selectedRow.useOricon,
        forecast: this.selectedRow.forecast,
        autoShipRequest: this.selectedRow.autoShipRequest,
        remarks: this.selectedRow.remarks,
        warehouseRemarks: this.selectedRow.warehouseRemarks,
        invoiceApproval: this.selectedRow.invoiceApproval,
        invoiceZipCode: this.selectedRow.invoiceZipCode,
        invoiceAddress: this.selectedRow.invoiceAddress,
        invoicePhoneNumber: this.selectedRow.invoicePhoneNumber,
        invoiceStaff: this.selectedRow.invoiceStaffCd,
        invoiceMailingAddress: this.selectedRow.invoiceMailingAddress,
        salesDepartment: this.selectedRow.salesDepartment,
        ourSalesStaff: this.selectedRow.ourSalesStaffCd,
        assistant: this.selectedRow.assistantCd,
        janDisplay: this.selectedRow.janDisplay,
        updateDatetime: this.selectedRow.updateDatetime,
      };
      this.editDialog.show = true;
    },
    async onBtnDelete() {
      try {
        const messageText = `選択された${this.screenName}情報を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: `${this.screenName}一覧`, text: messageText });
        if (ok) {
          const customers = {
            customers: this.selectionRows.map((r) => ({
              customerCode: r.customerCode,
              updateDatetime: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("customer/remove", customers);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`${this.screenName}情報が削除されました (${this.selectionRows.length}件)`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.customerCode]) {
                  tmp = {
                    customerCode: r.customerCode,
                    errorMsg: result.data.header.messages[r.customerCode]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "社店CD",
                    field: "customerCode",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("CustomerSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onBtnUpdatePic() {
      try {
        const messageText = `選択された${this.screenName}情報で受注出荷の担当者を更新します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: `${this.screenName}一覧`, text: messageText });
        if (ok) {
          this.loadingOn();
          const customers = {
            CustomerCodes: this.selectionRows.map((r) => r.customerCode),
          };
          const result = await this.$store.dispatch("customer/updatePic", customers);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(
                `${this.screenName}情報で受注出荷の担当者を更新されました (${this.selectionRows.length}件)`,
                {
                  timeout: 2300,
                }
              );
              this.onSearchClick();
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("CustomerSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("customer/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: `${this.screenName}一覧`,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.customers).length === 0) {
          this.$dialog.warning({
            title: `${this.screenName}一覧`,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.customers);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("CustomerSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEntrySubmit(customer) {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`${this.screenName}を登録しました (${customer.customerCode})`, { timeout: 2300 });
        this.onSearchClick();
      } catch (error) {
        console.error("CustomerSearch::onEntrySubmit", error);
        this.loadingOff();
        this.apiRequestError(error);
      }
    },
    async onEditSubmit(updateModel) {
      try {
        this.selectedRow.corporateCode = updateModel.corporateCode;
        this.selectedRow.customerCode = updateModel.customerCode;
        this.selectedRow.customerName = updateModel.customerName;
        this.selectedRow.zipCode = updateModel.zipCode;
        this.selectedRow.prefecture = updateModel.prefecture;
        this.selectedRow.locationAddress = updateModel.locationAddress;
        this.selectedRow.locationBuilding = updateModel.locationBuilding;
        this.selectedRow.telNumber = updateModel.telNumber;
        this.selectedRow.faxNumber = updateModel.faxNumber;
        this.selectedRow.customerStaff = updateModel.customerStaff;
        this.selectedRow.inCompanyOrderMaxAmount = updateModel.inCompanyOrderMaxAmount;
        this.selectedRow.customerStatus = updateModel.customerStatus;
        this.selectedRow.paymentTermsClosingDate = updateModel.paymentTermsClosingDate;
        this.selectedRow.paymentTermsPaymentDate = updateModel.paymentTermsPaymentDate;
        this.selectedRow.transactionStartDate = updateModel.transactionStartDate;
        this.selectedRow.shipmentDayOfWeek = updateModel.shipmentDayOfWeek;
        this.selectedRow.useOricon = updateModel.useOricon;
        this.selectedRow.forecast = updateModel.forecast;
        this.selectedRow.autoShipRequest = updateModel.autoShipRequest;
        this.selectedRow.remarks = updateModel.remarks;
        this.selectedRow.warehouseRemarks = updateModel.warehouseRemarks;
        this.selectedRow.invoiceApproval = updateModel.invoiceApproval;
        this.selectedRow.invoiceZipCode = updateModel.invoiceZipCode;
        this.selectedRow.invoiceAddress = updateModel.invoiceAddress;
        this.selectedRow.invoicePhoneNumber = updateModel.invoicePhoneNumber;
        this.selectedRow.invoiceStaff = updateModel.invoiceStaff;
        this.selectedRow.invoiceStaffCd = updateModel.invoiceStaffCd;
        this.selectedRow.invoiceMailingAddress = updateModel.invoiceMailingAddress;
        this.selectedRow.salesDepartment = updateModel.salesDepartment;
        this.selectedRow.ourSalesStaff = updateModel.ourSalesStaff;
        this.selectedRow.ourSalesStaffCd = updateModel.ourSalesStaffCd;
        this.selectedRow.assistant = updateModel.assistant;
        this.selectedRow.assistantCd = updateModel.assistantCd;
        this.selectedRow.janDisplay = updateModel.janDisplay;
        this.selectedRow.updateDatetime = updateModel.updateDatetime;

        this.gridApi.applyTransaction({ update: [updateModel] });
        this.editDialog.show = false;
        this.$dialog.notify.info(`${this.screenName}情報を更新しました (${updateModel.customerCode})`, {
          timeout: 2300,
        });
      } catch (error) {
        console.error("CustomerSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      if (canceled) {
        this.onSearchClick();
      }
    },
    async onBtnPdfExport() {
      try {
        this.loadingOn();
        const customers = {
          customer: {
            customerCode: this.selectedRow.customerCode,
          },
        };
        const result = await this.$store.dispatch("customer/outAddress", customers);
        let error = result.data?.header;
        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
            this.selectedRow.isPrint = true;
            this.gridApi.applyTransaction({ update: [this.selectedRow] });
            window.open(result.data.contents.exportFilePath, "_blank");
            this.$dialog.notify.info(`宛名を印刷しました (${this.selectedRow.customerCode})`, { timeout: 2300 });
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            var message = "";
            Object.keys(result.data?.header.messages).forEach(function (key) {
              message += result.data?.header.messages[key].join("<br>");
            });
            if (message != "") {
              this.$dialog.warning({
                title: `宛名印刷`,
                text: message,
                actions: ["OK"],
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("CustomerSearch::onBtnPrint", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
