<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>取引先変換一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <search-conditions @search="onSearchClick">
          <corporation-field
            ref="corporations"
            :group="vendorCorporationType"
            :value="searchModel.corporateCode"
            v-model="searchModel.corporateCode"
            :attach="false"
          ></corporation-field>
        </search-conditions>
        <tooltip-icon-button icon="mdi-refresh" @click="onSearchClick">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-pencil-plus-outline" @click="onBtnInsert" v-if="allowedAction('C070102')"
          >新規登録</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction('C070103')"
          :disabled="!selectedRow"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <conversion-entry
            :inputModel="editModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></conversion-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction('C070104')"
          :disabled="selectionRows.length === 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction('C070105')"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="ConversionList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.conversionCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="ConversionInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <conversion-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :conversionCode="selectedRow != null ? selectedRow.conversionCode : null"
        ></conversion-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { FullDateColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { SelectionFilter } from "../../components/ag-grid/filters";
import ConversionInfos from "../../components/conversion/ConversionInfos.vue";
import ConversionEntry from "../../components/conversion/ConversionEntry.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConversionTypes from "../../consts/ConversionTypes";
import CorporationTypes from "../../consts/CorporationTypes";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "ConversionSearch",
  components: {
    AgGridVue,
    ConversionInfos,
    ConversionEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    CorporationField,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        { headerName: "取引先変換番号", field: "conversionCode", pinned: "left" },
        {
          headerName: "分類",
          field: "conversionType",
          filter: "dpSelectionFilter",
          filterParams: { options: ConversionTypes.all() },
          valueGetter: (params) => ConversionTypes.of(params.data.conversionType),
        },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "相手先CD", field: "partnerCode" },
        { headerName: "相手先名称", field: "partnerStoreName" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "営業担当者", field: "salesStaff" },
        { headerName: "備考", field: "remarks" },
      ],
      defaultCsvExportParams: {
        allColumns: true,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {
        corporateCode: "",
      },
      editDialog: { show: false, width: "800px" },
      editModel: {
        createNew: false,
        rowId: null,
        conversionCode: null,
        conversionType: null,
        corporateCode: null,
        cafereoCode: null,
        customerCode: null,
        partnerCode: null,
        remarks: null,
      },
      columnTypes: {
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
      },
      vendorCorporationType: CorporationTypes.VENDOR,
    };
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.conversionCode, this.gridApi, this.columnApi);
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("conversion/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "取引先変換一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.conversions).length === 0) {
          this.$dialog.warning({
            title: "取引先変換一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.conversions);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("ConversionSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnInsert() {
      this.editModel = {
        createNew: true,
        conversionCode: null,
        conversionType: null,
        corporateCode: null,
        cafereoCode: null,
        customerCode: null,
        partnerCode: null,
        partnerStoreName: null,
        remarks: null,
        salesStaff: null,
        updateDatetime: null,
      };
      this.editDialog.show = true;
    },
    async onEntrySubmit(updateModel) {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`取引先変換情報を登録しました (${updateModel.conversionCode})`, { timeout: 2300 });
        this.onSearchClick();
      } catch (error) {
        console.error("ConversionSearch::onEntrySubmit", error);
        this.loadingOff();
        this.apiRequestError(error);
      }
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
        conversionCode: this.selectedRow.conversionCode,
        conversionType: this.selectedRow.conversionType,
        corporateCode: this.selectedRow.corporateCode,
        cafereoCode: this.selectedRow.cafereoCode,
        customerCode: this.selectedRow.customerCode,
        partnerCode: this.selectedRow.partnerCode,
        partnerStoreName: this.selectedRow.partnerStoreName,
        remarks: this.selectedRow.remarks,
        salesStaff: this.selectedRow.salesStaff,
        updateDatetime: this.selectedRow.updateDatetime,
      };
      this.editDialog.show = true;
    },
    async onEditSubmit(updateModel) {
      try {
        this.selectedRow.conversionCode = updateModel.conversionCode;
        this.selectedRow.conversionType = updateModel.conversionType;
        this.selectedRow.corporateCode = updateModel.corporateCode;
        this.selectedRow.corporateName = updateModel.corporateName;
        this.selectedRow.customerCode = updateModel.customerCode;
        this.selectedRow.cafereoCode = updateModel.cafereoCode;
        this.selectedRow.customerName = updateModel.customerName;
        this.selectedRow.partnerCode = updateModel.partnerCode;
        this.selectedRow.partnerStoreName = updateModel.partnerStoreName;
        this.selectedRow.remarks = updateModel.remarks;
        this.selectedRow.salesStaff = updateModel.salesStaff;
        this.selectedRow.updateDatetime = updateModel.updateDatetime;
        this.selectedRow.updateUser = updateModel.updateUser;
        this.gridApi.applyTransaction({ update: this.selectionRows });
        this.editDialog.show = false;
        this.$dialog.notify.info(`取引先変換情報を更新しました (${updateModel.conversionCode})`, { timeout: 2300 });
      } catch (error) {
        console.error("ConversionSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onBtnDelete() {
      try {
        this.loadingOn();
        const messageText = `選択された取引先変換情報を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "取引先変換一覧", text: messageText });
        if (ok) {
          const conversions = {
            conversions: this.selectionRows.map((r) => ({
              conversionCode: r.conversionCode,
              updateDatetime: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("conversion/remove", conversions);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`取引先変換情報が削除されました (${this.selectionRows.length}件)`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.conversionCode]) {
                  tmp = {
                    conversionCode: r.conversionCode,
                    errorMsg: result.data.header.messages[r.conversionCode]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "取引先変換番号",
                    field: "conversionCode",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("CorporationSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `取引先変換一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
  },
};
</script>
