<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-btn icon v-if="infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-minimize</v-icon></v-btn
        >
        <v-btn icon v-if="!infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2" hide-slider>
          <v-tab>取引先変換詳細</v-tab>
          <v-tab>履歴</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item>
            <conversion-details :style="detaleStyle" :details="details"></conversion-details>
          </v-tab-item>
          <v-tab-item>
            <conversion-history :conversionCode="conversionCode"></conversion-history>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConversionDetails from "./ConversionDetails.vue";
import ConversionHistory from "./ConversionHistory.vue";

export default {
  name: "ConversionInfos",
  props: ["details", "conversionCode"],
  data: () => ({
    tab: null,
    items: ["1", "2"],
    infoMaximum: false,
    detaleStyle: {
      height: window.innerHeight - 260 + "px",
      overflow: "scroll",
    },
  }),
  components: {
    ConversionDetails,
    ConversionHistory,
  },
  methods: {
    onInfoMaximum: function () {
      this.infoMaximum = !this.infoMaximum;
      this.$emit("infoMaximum-event");
    },
    handleResize: function () {
      this.detaleStyle.height = window.innerHeight - 260 + "px";
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
