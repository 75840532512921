<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>取引先変換登録</span>
      <span class="headline" v-if="!inputModel.createNew"><v-icon>mdi-pencil-outline</v-icon>取引先変換編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="conversionForm" v-model="validEntryForm" lazy-validation>
        <v-container style="max-width: inherit">
          <v-row dense v-if="!inputModel.createNew">
            <v-col cols="12" sm="4">
              <v-text-field label="取引先変換番号" v-model="updateModel.conversionCode" filled dense disabled>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8">
              <corporation-field
                label="法人名"
                :group="vendorCorporationType"
                v-model="updateModel.corporateCode"
                :rules="[rules.required]"
              ></corporation-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                label="分類"
                clearable
                dense
                filled
                v-model="updateModel.conversionType"
                :items="conversionTypes"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <customer-field
                v-if="isConversionTypeCustomer"
                :label="'取引先名'"
                v-model="updateModel.cafereoCode"
                :corporate="updateModel.corporateCode"
                :rules="[rules.required]"
                disabled-no-data
              ></customer-field>
              <customer-field
                v-if="isConversionTypeDelivery"
                :label="'取引先名'"
                v-model="updateModel.customerCode"
                :corporate="updateModel.corporateCode"
                :rules="[rules.required]"
                disabled-no-data
              ></customer-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <delivery-field
                v-if="isConversionTypeDelivery"
                v-model="updateModel.cafereoCode"
                disabled-no-data
                :groupCode="updateModel.customerCode"
                :rules="[rules.required]"
              ></delivery-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="相手先CD"
                v-model="updateModel.partnerCode"
                :rules="[rules.required, rules.maxLength(12)]"
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="相手先取引先名称"
                v-model="updateModel.partnerStoreName"
                :rules="[rules.maxLength(150)]"
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="営業担当者"
                v-model="updateModel.salesStaff"
                :rules="[rules.maxLength(150)]"
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="備考" v-model="updateModel.remarks" :rules="[rules.maxLength(100)]" filled dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import ConversionTypes from "../../consts/ConversionTypes";
import CorporationTypes from "../../consts/CorporationTypes";
import CorporationField from "../common/fields/CorporationField.vue";
import CustomerField from "../common/fields/CustomerField.vue";
import DeliveryField from "../common/fields/DeliveryField.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
export default {
  name: "ConversionEntry",
  props: ["inputModel"],
  components: { CorporationField, CustomerField, DeliveryField },
  data() {
    return {
      conversionTypes: ConversionTypes.all(),
      vendorCorporationType: CorporationTypes.VENDOR,
      updateModel: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
      },
      validEntryForm: null,
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
      if (this.$refs.conversionForm) {
        this.$refs.conversionForm.resetValidation();
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    isConversionTypeCustomer() {
      return this.updateModel.conversionType === ConversionTypes.CUSTOMER;
    },
    isConversionTypeDelivery() {
      return this.updateModel.conversionType === ConversionTypes.DELIVERY;
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = {
        createNew: inputModel.createNew,
        conversionCode: inputModel.conversionCode,
        conversionType: inputModel.conversionType,
        corporateCode: inputModel.corporateCode,
        cafereoCode: inputModel.cafereoCode,
        customerCode: inputModel.customerCode,
        partnerCode: inputModel.partnerCode,
        partnerStoreName: inputModel.partnerStoreName,
        salesStaff: inputModel.salesStaff,
        remarks: inputModel.remarks,
        updateDatetime: inputModel.updateDatetime,
      };
    },
    reset() {
      this.initUpdateModel(this.updateModel);
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("conversion/update", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents.conversion);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              this.$dialog.warning({
                title: `取引先変換編集`,
                text: result.data.header.messages[this.updateModel.conversionCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ConversionUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("conversion/create", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEntrySubmit", result.data.contents.conversion);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.$dialog.warning({
                title: `取引先変換登録`,
                text: result.data.header.messages[this.updateModel.corporateCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
          this.loadingOff();
        } catch (error) {
          console.error("ConversionUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },

    validate() {
      const isValid = this.$refs.conversionForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
