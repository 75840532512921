<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(false)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。</p>
              <p v-if="errorRows.length > 0">取引先情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(true)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="取引先一括取込エラー一覧"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import CustomerStatuses from "../../consts/CustomerStatus";
import ShipmentDayOfWeek from "../../consts/ShipmentDayOfWeek";
import { VendorPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import { CheckmarkCellRenderer } from "../../models/ag-grid/cellRenderers";
import JanDisplay from "../../consts/JanDisplay";

export default {
  name: "CustomerImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridApi: null,
    columnApi: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [
        { headerName: "法人CD", field: "corporateCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "郵便番号", field: "zipCode" },
        { headerName: "都道府県", field: "prefecture" },
        { headerName: "所在地（住所）", field: "locationAddress" },
        { headerName: "所在地（ビル名）", field: "locationBuilding" },
        { headerName: "電話番号", field: "telNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "担当者", field: "customerStaff" },
        { headerName: "社内発注上限金額", field: "inCompanyOrderMaxAmount" },
        {
          headerName: "ステータス",
          field: "customerStatus",
          filterParams: { options: CustomerStatuses.all() },
          valueGetter: (params) => CustomerStatuses.of(params.data.customerStatus),
        },
        {
          headerName: "支払条件締日",
          field: "paymentTermsClosingDate",
          filterParams: { options: VendorPaymentTermsClosingDate.all() },
          valueGetter: (params) => VendorPaymentTermsClosingDate.of(params.data.paymentTermsClosingDate),
        },
        {
          headerName: "支払条件支払日",
          field: "paymentTermsPaymentDate",
          filterParams: { options: VendorPaymentTermsPaymentDate.all() },
          valueGetter: (params) => VendorPaymentTermsPaymentDate.of(params.data.paymentTermsPaymentDate),
        },
        { headerName: "取引開始年月日", field: "transactionStartDate" },
        { headerName: "請求先〒", field: "invoiceZipCode" },
        { headerName: "請求先住所", field: "invoiceAddress" },
        { headerName: "請求先TEL", field: "invoicePhoneNumber" },
        { headerName: "請求担当者", field: "invoiceStaff" },
        { headerName: "請求書宛名", field: "invoiceMailingAddress" },
        { headerName: "備考", field: "remarks" },
        { headerName: "倉庫備考", field: "warehouseRemarks" },
        {
          headerName: "請求書発行可否",
          field: "invoiceApproval",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "可能", false: "不可" } },
          cellRenderer: CheckmarkCellRenderer(),
        },
        {
          headerName: "出荷曜日指定",
          field: "shipmentDayOfWeek",
          filterParams: { options: ShipmentDayOfWeek.all() },
          valueGetter: (params) => {
            let shipmentDayOfWeekName = "";
            params.data.shipmentDayOfWeek.forEach(function (elem) {
              if (shipmentDayOfWeekName != "") {
                shipmentDayOfWeekName += ",";
              }
              shipmentDayOfWeekName += ShipmentDayOfWeek.of(elem);
            });
            return shipmentDayOfWeekName;
          },
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          cellRenderer: CheckmarkCellRenderer(),
        },
        {
          headerName: "自動出荷希望",
          field: "autoShipRequest",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "希望あり", false: "希望なし" } },
          cellRenderer: CheckmarkCellRenderer(),
        },
        {
          headerName: "フォーキャスト可否",
          field: "forecast",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "可能", false: "不可" } },
          cellRenderer: CheckmarkCellRenderer(),
        },
        { headerName: "担当部署", field: "salesDepartment" },
        { headerName: "営業担当", field: "ourSalesStaff" },
        { headerName: "担当AS", field: "assistant" },
        {
          headerName: "JAN表示",
          field: "janDisplay",
          filterParams: { options: JanDisplay.all() },
          valueGetter: (params) => JanDisplay.of(params.data.janDisplay),
        },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxByteLength: Validation.maxByteLength,
      maxLength: Validation.maxLength,
      phoneNo: Validation.phoneNo,
      mailAddress: Validation.mailAddress,
      zipCode: Validation.isZipcode,
      isCsv: Validation.isCsv,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          const csvText = await FileUtils.readAsText(this.file, true);
          const csvLines = csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
          this.csvRecords = [];
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              this.csvRecords.push({
                rowNumber: index,
                corporateCode: line[0],
                corporateName: line[1],
                customerName: line[2],
                zipCode: line[3],
                prefecture: line[4],
                locationAddress: line[5],
                locationBuilding: line[6],
                telNumber: line[7],
                faxNumber: line[8],
                customerStaff: line[9],
                inCompanyOrderMaxAmount: line[10],
                customerStatus: line[11],
                paymentTermsClosingDate: line[12],
                paymentTermsPaymentDate: line[13],
                transactionStartDate: line[14],
                invoiceZipCode: line[15],
                invoiceAddress: line[16],
                invoicePhoneNumber: line[17],
                invoiceStaff: line[18],
                invoiceMailingAddress: line[19],
                remarks: line[20],
                warehouseRemarks: line[21],
                invoiceApproval: line[22] ? JSON.parse(line[22]) : "",
                shipmentDayOfWeek: line[23]
                  ?.split(":")
                  .map((str) => parseInt(str, 10))
                  .filter((n) => n),
                useOricon: line[24] ? JSON.parse(line[24]) : "",
                autoShipRequest: line[25] ? JSON.parse(line[25]) : "",
                forecast: line[26] ? JSON.parse(line[26]) : "",
                salesDepartment: line[27],
                ourSalesStaff: line[28],
                assistant: line[29]?.split(":"),
                janDisplay: line[30] ? JSON.parse(line[30]) : "",
              });
            }
          });
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async validLoop(records) {
      let errors = true;
      for await (const record of records) {
        const error = await this.validateRow(record);
        if (error != true) {
          errors = false;
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
        }
      }
      return errors;
    },
    async onSubmit() {
      this.loadingOn();
      try {
        this.errorRows = [];
        const error = await this.validLoop(this.csvRecords);
        if (error) {
          let requestRecords = [];
          this.csvRecords.forEach(async (record) => {
            requestRecords.push(this.requestFormat(record));
          });
          this.errorRows = [];
          var param = {
            customers: requestRecords,
          };
          const response = await this.$store.dispatch("customer/import", param);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importCount = response.data.contents.customers.length;
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.$refs.importErrorGrid.open({ records: this.errorRows });
          this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("LogisticsStockImport::onSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    async validateRow(row) {
      var ret = true;
      var messages = [];
      // 法人CD
      this.setValidMessage(this.rules.required(row.corporateCode), "法人CD", messages);
      this.setValidMessage(await this.isCorporation(row.corporateCode), "法人CD", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.corporateCode), "法人CD", messages);
      // 取引先名
      this.setValidMessage(this.rules.required(row.customerName), "取引先名", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.customerName), "取引先名", messages);
      // 郵便番号
      this.setValidMessage(this.rules.zipCode(row.zipCode), "郵便番号", messages);
      this.setValidMessage(await this.isDeliveryZipArea(row.zipCode), "郵便番号", messages);
      // 都道府県
      this.setValidMessage(this.rules.maxLength(4)(row.prefecture), "都道府県", messages);
      // 所在地（住所）
      this.setValidMessage(this.rules.maxLength(150)(row.locationAddress), "所在地（住所）", messages);
      // 所在地（ビル名）
      this.setValidMessage(this.rules.maxLength(150)(row.locationBuilding), "所在地（ビル名）", messages);
      // 電話番号
      this.setValidMessage(this.rules.phoneNo(row.telNumber), "電話番号", messages);
      // FAX番号
      this.setValidMessage(this.rules.phoneNo(row.faxNumber), "FAX番号", messages);
      // 担当者
      this.setValidMessage(this.rules.maxLength(150)(row.customerStaff), "担当者", messages);
      // 社内発注上限金額
      this.setValidMessage(this.rules.isNumber(row.inCompanyOrderMaxAmount), "社内発注上限金額", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.inCompanyOrderMaxAmount), "社内発注上限金額", messages);
      // ステータス
      this.setValidMessage(this.rules.required(row.customerStatus), "ステータス", messages);
      this.setValidMessage(this.isStatus(row.customerStatus), "ステータス", messages);
      // 支払条件締日
      this.setValidMessage(this.isPaymentTermsClosingDate(row.paymentTermsClosingDate), "支払条件締日", messages);
      // 支払条件支払日
      this.setValidMessage(this.isPaymentTermsPaymentDate(row.paymentTermsPaymentDate), "支払条件支払日", messages);
      // 取引開始年月日
      this.setValidMessage(this.rules.required(row.transactionStartDate), "取引開始年月日", messages);
      this.setValidMessage(this.isDate(row.transactionStartDate), "取引開始年月日", messages);
      // 請求先〒
      this.setValidMessage(this.rules.zipCode(row.invoiceZipCode), "請求先〒", messages);
      // 請求先住所
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceAddress), "請求先住所", messages);
      // 請求先TEL
      this.setValidMessage(this.rules.phoneNo(row.invoicePhoneNumber), "請求先TEL", messages);
      // 請求担当者
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceStaff), "請求担当者", messages);
      // 請求書宛名
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceMailingAddress), "請求書宛名", messages);
      // 備考
      this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
      // 倉庫備考
      this.setValidMessage(this.rules.maxLength(150)(row.warehouseRemarks), "倉庫備考", messages);
      // 請求書発行可否
      this.setValidMessage(this.rules.required(row.invoiceApproval), "請求書発行可否", messages);
      this.setValidMessage(this.isBoolean(row.invoiceApproval), "請求書発行可否", messages);
      // 出荷曜日指定
      this.setValidMessage(this.isShipmentDayOfWeek(row.shipmentDayOfWeek), "出荷曜日指定", messages);
      // オリコン指定
      this.setValidMessage(this.rules.required(row.useOricon), "オリコン指定", messages);
      this.setValidMessage(this.isBoolean(row.useOricon), "オリコン指定", messages);
      // 自動出荷希望
      this.setValidMessage(this.rules.required(row.autoShipRequest), "自動出荷希望", messages);
      this.setValidMessage(this.isBoolean(row.autoShipRequest), "自動出荷希望", messages);
      // フォーキャスト可否
      this.setValidMessage(this.rules.required(row.forecast), "フォーキャスト可否", messages);
      this.setValidMessage(this.isBoolean(row.forecast), "フォーキャスト可否", messages);
      // 営業担当
      this.setValidMessage(this.rules.required(row.ourSalesStaff), "営業担当", messages);
      this.setValidMessage(await this.isOurSalesStaff(row.ourSalesStaff), "営業担当", messages);
      // 担当AS
      this.setValidMessage(await this.isAssistant(row.assistant), "担当AS", messages);
      //JAN表示
      this.setValidMessage(this.rules.required(row.janDisplay), "JAN表示", messages);
      this.setValidMessage(this.isBoolean(row.janDisplay), "JAN表示", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        corporateCode: this.stringFormat(row.corporateCode),
        corporateName: this.stringFormat(row.corporateName),
        customerName: this.stringFormat(row.customerName),
        zipCode: this.stringFormat(row.zipCode),
        prefecture: this.stringFormat(row.prefecture),
        locationAddress: this.stringFormat(row.locationAddress),
        locationBuilding: this.stringFormat(row.locationBuilding),
        telNumber: this.stringFormat(row.telNumber),
        faxNumber: this.stringFormat(row.faxNumber),
        customerStaff: this.stringFormat(row.customerStaff),
        inCompanyOrderMaxAmount: this.numberFormat(row.inCompanyOrderMaxAmount),
        customerStatus: this.numberFormat(row.customerStatus),
        paymentTermsClosingDate: this.numberFormat(row.paymentTermsClosingDate),
        paymentTermsPaymentDate: this.numberFormat(row.paymentTermsPaymentDate),
        transactionStartDate: this.stringDateFormat(row.transactionStartDate),
        invoiceZipCode: this.stringFormat(row.invoiceZipCode),
        invoiceAddress: this.stringFormat(row.invoiceAddress),
        invoicePhoneNumber: this.stringFormat(row.invoicePhoneNumber),
        invoiceStaff: this.stringFormat(row.invoiceStaff),
        invoiceMailingAddress: this.stringFormat(row.invoiceMailingAddress),
        remarks: this.stringFormat(row.remarks),
        warehouseRemarks: this.stringFormat(row.warehouseRemarks),
        invoiceApproval: this.boolFormat(row.invoiceApproval),
        shipmentDayOfWeek: row.shipmentDayOfWeek ? row.shipmentDayOfWeek : new Array(),
        useOricon: this.boolFormat(row.useOricon),
        autoShipRequest: this.boolFormat(row.autoShipRequest),
        forecast: this.boolFormat(row.forecast),
        salesDepartment: this.stringFormat(row.salesDepartment),
        ourSalesStaff: this.stringFormat(row.ourSalesStaff),
        assistant: row.assistant,
        janDisplay: this.boolFormat(row.janDisplay),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(new Date(value)).format("YYYY-MM-DD");
    },
    boolFormat(value) {
      if (value == null || value == "") return false;
      return value == 1;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(new Date(value), "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isStatus(value) {
      if (value == null || value == "") return true;
      if (!CustomerStatuses.of(value)) return "1～3の形式で入力してください";
      return true;
    },
    async isCorporation(value) {
      if (value == null || value == "") return true;
      try {
        let res = await this.$store.dispatch("corporation/search", {
          corporateCode: value,
        });
        if (
          res.data.contents.corporations.length == 1 &&
          res.data.contents.corporations[0].corporationType == 3 &&
          res.data.contents.corporations[0].corporateStatus == 1
        ) {
          return true;
        } else {
          return "法人マスタに存在する法人CDで入力してください";
        }
      } catch (error) {
        console.error("CustomerImport::isCorporation", error);
        this.apiRequestError(error);
      }
    },
    async isDeliveryZipArea(value) {
      if (value == null || value == "") return true;
      let res = await this.$store.dispatch("deliveryArea/isZipCode", {
        zipCode: value,
      });
      if (!res.data.contents.isZipCode) return "配送エリアマスタに存在する郵便番号で入力してください";
      return true;
    },
    isPaymentTermsClosingDate(value) {
      if (value == null || value == "") return true;
      if (!VendorPaymentTermsClosingDate.of(value)) return "1～6の形式で入力してください";
      return true;
    },
    isPaymentTermsPaymentDate(value) {
      if (value == null || value == "") return true;
      if (!VendorPaymentTermsPaymentDate.of(value)) return "1～12の形式で入力してください";
      return true;
    },
    isShipmentDayOfWeek(value) {
      if (value == null || value == "") return true;
      for (const elem of value) {
        if (!ShipmentDayOfWeek.of(elem)) {
          return "1～7の形式で入力してください";
        }
      }
      return true;
    },
    async isOurSalesStaff(value) {
      if (value == null || value == "") return true;
      try {
        const response = await this.$store.dispatch("cafereoUser/search", {
          inactivated: false,
        });
        let bool = false;
        for await (const user of response.data.contents.users) {
          if (user.userId === value) {
            bool = true;
            break;
          }
        }
        if (bool) return true;
        return "ユーザマスタに存在するユーザIDで入力してください";
      } catch (error) {
        console.error("CustomerImport::isOurSalesStaff", error);
        this.apiRequestError(error);
      }
    },
    async isAssistant(value) {
      if (value == null || value == "") return true;
      try {
        const response = await this.$store.dispatch("cafereoUser/search", {
          inactivated: false,
        });
        let bool = false;
        const lastkey = value[Object.keys(value).pop()];
        for await (const valUser of value) {
          let tmpBool = false;
          for await (const user of response.data.contents.users) {
            if (user.userId === valUser) {
              tmpBool = true;
              break;
            }
          }
          if (!tmpBool) {
            bool = false;
            break;
          }
          if (lastkey == valUser) {
            bool = true;
          }
        }
        if (!bool) return "ユーザマスタに存在するユーザIDで入力してください";
        return true;
      } catch (error) {
        console.error("CustomerImport::isAssistant", error);
        this.apiRequestError(error);
      }
    },
    isBoolean(value) {
      if (value == null || value == "") return true;
      if (value !== 0 && value !== 1) return "0または1の形式で入力してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
