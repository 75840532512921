<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-database-edit</v-icon>会社情報更新</v-app-bar-title>
        <v-spacer></v-spacer>
        <tooltip-icon-button v-if="false" icon="mdi-check" @click="onUpdateClick">保存</tooltip-icon-button>
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col :cols="12">
        <v-form ref="corporationForm" v-model="validEntryForm" lazy-validation>
          <v-container style="max-width: inherit">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="法人CD" v-model="updateModel.corporateCode" filled dense readonly> </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field label="法人名" v-model="updateModel.corporationName" filled dense readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-text-field label="電話番号" v-model="updateModel.phoneNumber" filled dense readonly></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field label="FAX番号" v-model="updateModel.faxNumber" filled dense readonly> </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-text-field label="郵便番号" v-model="updateModel.zipCode" filled dense readonly> </v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field label="都道府県" v-model="updateModel.prefecture" filled dense readonly> </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="所在地（住所）"
                  v-model="updateModel.locationAddress"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="所在地（ビル名）"
                  v-model="updateModel.locationBuildingName"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "CorporationEdit",
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
      },
      updateModel: {
        corporateCode: null,
        corporationName: null,
        phoneNumber: null,
        faxNumber: null,
        zipCode: null,
        prefecture: null,
        locationAddress: null,
        locationBuildingName: null,
        updateDatetime: null,
      },
      validEntryForm: null,
    };
  },
  components: {
    TooltipIconButton,
  },
  beforeMount() {
    if (this.$refs.corporationForm) {
      this.$refs.corporationForm.resetValidation();
    }
    this.onSearchClick();
  },
  methods: {
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("corporation/search");

        if (ApiStatus.isSystemError(response?.data?.header)) {
          return this.redirectError();
        }
        this.updateModel = {
          corporateCode: response.data.contents.corporations[0].corporateCode,
          corporationName: response.data.contents.corporations[0].corporationName,
          phoneNumber: response.data.contents.corporations[0].phoneNumber,
          faxNumber: response.data.contents.corporations[0].faxNumber,
          zipCode: response.data.contents.corporations[0].zipCode,
          prefecture: response.data.contents.corporations[0].prefecture,
          locationAddress: response.data.contents.corporations[0].locationAddress,
          locationBuildingName: response.data.contents.corporations[0].locationBuildingName,
          updateDatetime: response.data.contents.corporations[0].updateDatetime,
        };
      } catch (error) {
        console.error("CorporationSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("corporation/update", { corporation: this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              this.$dialog.warning({
                title: `会社情報更新`,
                text: result.data.header.messages[this.updateModel.corporateCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.corporationForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
