<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>法人一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch">
            <v-text-field
              v-model="searchModel.corporateCode"
              label="法人CD"
              :rules="[rules.isCode, rules.maxLength(12)]"
              dense
            ></v-text-field>
            <v-text-field v-model="searchModel.corporateName" label="法人名" dense></v-text-field>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-button v-if="allowedAction('C070202')" icon="mdi-pencil-plus-outline" @click="onBtnInsert">
          新規登録</tooltip-icon-button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-button
          v-if="allowedAction('C070203')"
          :disabled="!selectedRow || selectedRow.corporationType == 1"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <corporation-entry
            :inputModel="corporationModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></corporation-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction('C070204')"
          :disabled="selectionRows.length === 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction('C070205')" icon="mdi-download" @click="onBtnExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="CorporationList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.corporateCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="CorporationInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <corporation-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :corporateCode="selectedRow != null ? selectedRow.corporateCode : null"
        ></corporation-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import CorporationInfos from "../../components/corporation/CorporationInfos.vue";
import CorporationEntry from "../../components/corporation/CorporationEntry.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import {
  NumericColumn,
  FullDateColumn,
  DateColumn,
  PercentColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import InvoiceTypes from "../../consts/InvoiceTypes";
import RoundingTypes from "../../consts/RoundingTypes";
import CorporationStatus from "../../consts/CorporationStatus";
import CorporationTypes from "../../consts/CorporationTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import moment from "moment";
import Validation from "../../libs/validation";

export default {
  name: "CorporationSearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isCode: Validation.isCode,
      },
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        { headerName: "法人CD", field: "corporateCode", pinned: "left" },
        {
          headerName: "ステータス",
          field: "corporateStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CorporationStatus.all() },
          valueGetter: (params) => CorporationStatus.of(params.data.corporateStatus),
        },
        { headerName: "担当部署", field: "salesDepartment" },
        { headerName: "営業担当", field: "salesStaffName" },
        { headerName: "担当AS", field: "salesAssistantName" },
        { headerName: "法人名", field: "corporationName" },
        {
          headerName: "法人区分",
          field: "corporationType",
          valueGetter: (params) => CorporationTypes.of(params.data.corporationType),
        },
        { headerName: "郵便番号", field: "zipCode" },
        { headerName: "都道府県", field: "prefecture" },
        { headerName: "所在地（住所）", field: "locationAddress" },
        { headerName: "所在地（ビル名）", field: "locationBuilding" },
        { headerName: "電話番号", field: "phoneNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "取引開始日", field: "startTradingDate", type: "dpDateColumn" },
        {
          headerName: "与信",
          field: "credit",
          filter: "dpBooleanFilter",
          filterParams: { caption: { true: "取得済", false: "未取得" } },
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "請求先区分",
          colId: "invoiceType",
          filter: "dpSelectionFilter",
          filterParams: { options: InvoiceTypes.all() },
          valueGetter: (params) => InvoiceTypes.of(params.data.invoiceType),
        },
        {
          headerName: "端数処理区分",
          colId: "roundingType",
          filter: "dpSelectionFilter",
          filterParams: { options: RoundingTypes.all() },
          valueGetter: (params) => RoundingTypes.of(params.data.roundingType),
        },
        { headerName: "備考", field: "description" },

        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      defaultCsvExportParams: {
        allColumns: true,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {
        corporateCode: "",
        corporateName: "",
      },
      editDialog: { show: false, width: "800px" },
      corporationModel: {
        createNew: false,
        corporateCode: null,
        corporationName: null,
        phoneNumber: null,
        faxNumber: null,
        zipCode: null,
        prefecture: null,
        location: null,
        corporateStatus: null,
        credit: null,
        invoiceType: null,
        roundingType: null,
        description: null,
        corporationType: null,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpPercentColumn: PercentColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
    };
  },
  components: {
    AgGridVue,
    CorporationInfos,
    CorporationEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.onSearchClick();
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.corporateCode, this.gridApi, this.columnApi);
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `法人一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnInsert() {
      this.corporationModel = {
        createNew: true,
        corporateCode: null,
        corporationName: null,
        phoneNumber: null,
        faxNumber: null,
        zipCode: null,
        prefecture: null,
        locationAddress: null,
        locationBuilding: null,
        corporationType: null,
        startTradingDate: null,
        credit: false,
        description: null,
        invoiceType: null,
        roundingType: null,
        corporateStatus: null,
        salesDepartment: null,
        salesStaff: null,
        salesAssistant: null,
        headOffice: null,
        updateDatetime: null,
      };
      this.editDialog.show = true;
    },
    onBtnUpdate() {
      this.corporationModel = {
        createNew: false,
        corporateCode: this.selectedRow.corporateCode,
        corporationName: this.selectedRow.corporationName,
        phoneNumber: this.selectedRow.phoneNumber,
        faxNumber: this.selectedRow.faxNumber,
        zipCode: this.selectedRow.zipCode,
        prefecture: this.selectedRow.prefecture,
        locationAddress: this.selectedRow.locationAddress,
        locationBuilding: this.selectedRow.locationBuilding,
        corporationType: this.selectedRow.corporationType,
        startTradingDate: moment(new Date(this.selectedRow.startTradingDate)).format("YYYY-MM-DD"),
        credit: this.selectedRow.credit,
        description: this.selectedRow.description,
        invoiceType: this.selectedRow.invoiceType,
        roundingType: this.selectedRow.roundingType,
        corporateStatus: this.selectedRow.corporateStatus,
        salesDepartment: this.selectedRow.salesDepartment,
        salesStaff: this.selectedRow.salesStaff,
        salesAssistant: this.selectedRow.salesAssistant,
        headOffice: this.selectedRow.headOffice,
        updateDatetime: this.selectedRow.updateDatetime,
      };
      this.editDialog.show = true;
    },
    async onBtnDelete() {
      try {
        this.loadingOn();
        const messageText = `選択された法人情報を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "法人一覧", text: messageText });
        if (ok) {
          const corporations = {
            corporations: this.selectionRows.map((r) => ({
              corporateCode: r.corporateCode,
              updateDatetime: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("corporation/remove", corporations);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`法人情報が削除されました (${this.selectionRows.length}件)`, { timeout: 2300 });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.corporateCode]) {
                  tmp = {
                    corporateCode: r.corporateCode,
                    errorMsg: result.data.header.messages[r.corporateCode]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "法人CD",
                    field: "corporateCode",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("CorporationSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("corporation/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "法人一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.corporations).length === 0) {
          this.$dialog.warning({
            title: "法人一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.corporations);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("CorporationSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEntrySubmit(corporation) {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`法人情報を登録しました (${corporation.corporateCode})`, { timeout: 2300 });
        this.onSearchClick();
      } catch (error) {
        console.error("CorporationSearch::onEntrySubmit", error);
        this.apiRequestError(error);
      }
    },
    async onEditSubmit(corporation) {
      try {
        this.selectedRow.corporateCode = corporation.corporateCode;
        this.selectedRow.corporationName = corporation.corporationName;
        this.selectedRow.phoneNumber = corporation.phoneNumber;
        this.selectedRow.faxNumber = corporation.faxNumber;
        this.selectedRow.zipCode = corporation.zipCode;
        this.selectedRow.prefecture = corporation.prefecture;
        this.selectedRow.locationAddress = corporation.locationAddress;
        this.selectedRow.locationBuilding = corporation.locationBuilding;
        this.selectedRow.corporateStatus = corporation.corporateStatus;
        this.selectedRow.startTradingDate = corporation.startTradingDate;
        this.selectedRow.credit = corporation.credit;
        this.selectedRow.invoiceType = corporation.invoiceType;
        this.selectedRow.roundingType = corporation.roundingType;
        this.selectedRow.salesDepartment = corporation.salesDepartment;
        this.selectedRow.salesStaff = corporation.salesStaff;
        this.selectedRow.salesStaffName = corporation.salesStaffName;
        this.selectedRow.salesAssistant = corporation.salesAssistant;
        this.selectedRow.salesAssistantName = corporation.salesAssistantName;
        this.selectedRow.headOffice = corporation.headOffice;
        this.selectedRow.description = corporation.description;
        this.selectedRow.corporationType = corporation.corporationType;
        this.selectedRow.updateDatetime = corporation.updateDatetime;
        this.selectedRow.updateUser = corporation.updateUser;
        this.gridApi.applyTransaction({ update: this.selectionRows });
        this.editDialog.show = false;
        this.$dialog.notify.info(`法人情報を更新しました (${corporation.corporateCode})`, { timeout: 2300 });
      } catch (error) {
        console.error("CorporationSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
